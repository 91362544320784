import * as Sentry from "@sentry/gatsby";

const env = process.env.NODE_ENV || process.env.GATSBY_ENV || "development";

let integrations = [
    Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/^https:\/\/nestjs\.moris\.co\.uk\/.*/],
    }),
    Sentry.browserTracingIntegration({}),
];

if (env === "development" && env !== "production") {
    integrations.push(
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "system",
        })
    );
}

Sentry.init({
    environment: env,
    dsn: "https://ecdd61e8939b4c0a11b650e58e5750d9@o4506427876966400.ingest.sentry.io/4506581228322816",

    tracePropagationTargets: [
        "https://nestjs.moris.co.uk",
        "https://trackdayinsurance.dk",
        "https://trackdayinsurance.com",
    ],
    integrations: integrations,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
