import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import React from "react";

const queryClient = new QueryClient();

const RootElement = ({children}) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default RootElement;
